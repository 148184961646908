.dd-logo {
  height: 56px;
  width: 56px;
  @media only screen and (max-width: 600px) {
    height: 40px;
    width: 40px;
  }
}

.hero-sketch {
  width: 260px;
  height: 260px;
}

.feature1-sketch {
  width: 375px;
  height: 352px;
}
